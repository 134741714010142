<template>
  <div>
    <div class="flex">
      <a-tabs type="card" :activeKey="activeTabKey" @change="handlerTabChange">
        <a-tab-pane :key="1" tab="活动蛋糕的每日制作上限"></a-tab-pane>
        <a-tab-pane :key="2" tab="各时段交付上限"></a-tab-pane>
        <!-- <template #tabBarExtraContent>
          <div class="flex justify-between">
            
          </div>
        </template> -->
      </a-tabs>
      <div style="flex:1;" class="flex justify-between items-center ml-4">
        <div class="flex items-center">
          <!-- <a-select v-model="activeCityAdcode" placeholder="城市区域"
            class="mr-4" style="width:110px;"
            @change="handlerCityChange">
            <a-select-option
              v-for="item in cityList"
              :value="item.adcode"
              :key="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select> -->
          <a-select placeholder="城市区域" style="width: 110px"
                    v-model="activeCityAdcode"
                    show-search
                    :default-active-first-option="false"
                    :filter-option="false"
                    :not-found-content="null"
                    @search="handlerSuggestCity"
                    @change="handlerCityChange"
                    @blur="handlerSuggestCityBlur">
            <a-select-option v-for="item in suggestCityList"
                             :key="item.adcode"
                             :value="item.adcode">{{ item.name }}</a-select-option>
          </a-select>
          <a-range-picker style="width: 240px;" @change="handlerSearch"
              v-model="dateArr" valueFormat="YYYY-MM-DD" />
        </div>

        <div>
          <!-- <a-button v-if="hasAuthExport" class="p-0" 
              @click="handlerExport" type="link">导出城市预约数据</a-button> -->
          <a-button v-if="hasAuthExport" class="p-0" 
              @click="handlerExport" type="link">导出光夜蛋糕预约数据</a-button>
          <a-button v-if="activeTabKey == 1" class="ml-8 p-0" 
              @click="handlerSetDaily" type="link">设置</a-button>
          <a-button v-if="activeTabKey == 2" class="ml-8 p-0" 
              @click="handlerSetHour" type="link">设置</a-button>
        </div>
      </div>
    </div>
    
    <base-table v-if="activeTabKey == 1" 
        rowKey="date"
        :columnsData="dailyLimitColumn"
        :tableData="dailyLimitData"
        :addHeight="10"
        @change="handlerLimitDailyChange">
    </base-table>

    <a-modal title="设置每日制作上限"
        v-if="isShowSetDailyStock"
        :visible.sync="isShowSetDailyStock"
        destroyOnClose
        class="set-daily-stock"
        width="600px"
        @ok="handlerSetDailyOk"
        @cancel="isShowSetDailyStock=false">
      <div>
        <a-form-model :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" :model="dailyForm">
          <a-form-model-item class="mb-2" label="城市区域">
            {{ activeCity.name }}
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="dateArr" label="日期">
            <a-range-picker v-model="dailyForm.dateArr" valueFormat="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="sku_id" label="产品">
            <a-select v-model="dailyForm.sku_ids" placeholder="选择产品" @change="onDailySkuChanged"
              mode="multiple">
              <a-select-option
                v-for="item in skuList"
                :value="item.sku_id"
                :key="item.sku_id"
              >{{ item.goods_name }} / {{ item.flavor_name }}{{ item.specification_name }}</a-select-option>
            </a-select>
            <div class="mt-4">
              <div class="flex justify-between items-center" 
                v-for="item in dailyForm.sku_selected"
                :value="item.sku_id" :key="item.sku_id">
                <div class="sku-item-name">{{item.goods_name}} / {{ item.flavor_name }}{{ item.specification_name }}</div>
                <a-input-number style="width: 100px;"
                  :step="1" v-model="item.number"
                  placeholder="上限数量"
                ></a-input-number>
              </div>
            </div>
          </a-form-model-item>
          <!-- <a-form-model-item class="mb-2" prop="stock" label="上限数量">
            <a-input-number style="width:100%;"
              :step="1" allowClear
              v-model="dailyForm.initial_stock"
              placeholder="上限数量"
            ></a-input-number>
          </a-form-model-item> -->
        </a-form-model>
      </div>
    </a-modal>


    <base-table v-if="activeTabKey == 2" 
        rowKey="date"
        :columnsData="hourLimitColumn"
        :tableData="hourLimitData"
        :addHeight="10"
        @change="handlerHourLimitChange" />

    <a-modal title="设置时段上限"
        v-if="isShowSetHourStock"
        :visible.sync="isShowSetHourStock"
        destroyOnClose
        width="500px"
        @ok="handlerSetHourOk"
        @cancel="isShowSetHourStock=false">
      <div>
        <a-form-model :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }" :model="hourForm">
          <a-form-model-item class="mb-2" label="城市区域">
            {{ activeCity.name }}
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="dateArr" label="日期">
            <a-range-picker v-model="hourForm.dateArr" valueFormat="YYYY-MM-DD" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="hourlist" label="时段">
            <div class="hour-con">
              <a-checkbox class="hour-item" v-model="checkAllHour" @change="onCheckAllHour">全部</a-checkbox>
              <a-checkbox class="hour-item" v-for="(item, index) in hourForm.hour_list" :key="index"
                v-model="item.checked">{{item.name}}</a-checkbox>
            </div>
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="initial_stock" label="上限数量">
            <a-input-number style="width:100%;"
              :step="1" allowClear
              v-model="hourForm.initial_stock"
              placeholder="上限数量"
            ></a-input-number>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>


    <!-- 导出 -->
    <a-modal title="导出城市预约数据"
        v-if="isShowExport"
        :visible.sync="isShowExport"
        destroyOnClose
        width="500px"
        :footer="null"
        @cancel="isShowExport=false">
      <div>
        <a-form-model :labelCol="{ span: 6 }" :wrapperCol="{ span: 18 }" :model="dailyForm">
          <a-form-model-item class="mb-2" prop="orderDateArr" label="下单日期">
            <a-range-picker :disabled="exportLoading"
              v-model="exportForm.orderDateArr" valueFormat="YYYY-MM-DD"
              @change="checkExportMode" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="dateArr" label="自提/配送日期">
            <a-range-picker :disabled="exportLoading"
              v-model="exportForm.dateArr" valueFormat="YYYY-MM-DD"
              @change="checkExportMode" />
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="mode" label="导出方式">
            <a-radio-group class="mode-radio" :disabled="exportLoading" 
              v-model="exportForm.mode">
              <a-radio :value="1">城市、产品</a-radio>
              <a-radio :value="2" :disabled="exportForm.orderDateArr.length!=2">城市、下单日期-产品</a-radio>
              <a-radio :value="3" :disabled="exportForm.orderDateArr.length!=2">城市-下单日期、产品（方便二次加工）</a-radio>
              <a-radio :value="4" :disabled="exportForm.dateArr.length!=2">城市、自提/配送日期-产品</a-radio>
              <a-radio :value="5" :disabled="exportForm.dateArr.length!=2">城市-自提/配送日期、产品（方便二次加工）</a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item class="mb-2" prop="sku_id" label="选择产品">
            <a-select v-model="exportForm.goods_ids" mode="multiple" placeholder="选择产品"
              :disabled="exportLoading">
              <a-select-option
                v-for="item in goodsList"
                :value="item.goods_id"
                :key="item.goods_id"
              >{{ item.goods_name }}</a-select-option>
            </a-select>
          </a-form-model-item>
        </a-form-model>
        <div class="mt-10 mb-5 flex justify-center items-center">
          <a-button type="primary" :loading="exportLoading" @click="handlerExportOk">导出</a-button>
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>

import {
  getFinanceUnionCityList,
} from "@/api/outlets"
import {
  getSpecialSkuList,
} from "@/api/goods"
import {
  getDayActGoodsMaxStock,
  setDayActGoodsMaxStock,
  getHourActMaxStock,
  setHourActMaxStock,
  getActGoodsStatistics
} from "@/api/regional/act-goods"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'
import { 
  checkAuth,
} from '@/utils/authData.js'

export default {
  components: {

  },
  data() {
    return {
      wrapperCol: { span: 18 },
      labelCol: { span: 6 },

      activeTabKey: 1,

      isCityManage: isCityManage(),
      manageCityList: [],
      cityList: [],
      uniCityList: [],
      suggestCityList: [],

      activeCity: null,
      activeCityAdcode: null,
      searchForm: {
        outlets_city_adcode: "",
        page: 1,
        page_count: 40,
      },
      dateArr: [],

      tableData_stock: [],
      isShowEditStock: false,
      activeRow: null,

      skuList: [],
      dailyLimitColumn: [],
      dailyLimitData: [],

      // 产品每日上限
      isShowSetDailyStock: false,
      dailyForm: {
        sku_id: undefined,
        sku_ids: [],
        sku_selected: [],
        dateArr: [],
        initial_stock: 0,
      },

      // 时段上限
      isShowSetHourStock: false,
      hourForm: {
        dateArr: [],
        hour_list: [],
        initial_stock: 0,
      },
      hourList: [
        // {
        //   hour: 8,
        //   name: "08:00~09:00",
        // }
      ],
      checkAllHour: false,
      hourLimitColumn: [],
      hourLimitData: [],

      // 导出
      isShowExport: false,
      exportForm: {
        orderDateArr: [],
        dateArr: [],
        goods_ids: [],
        date: '',
        mode: 1,
      },
      goodsList: [],
      exportLoading: false,
      hasAuthExport: checkAuth("city-day-limitation:statistics"),

      loading: false,
    };
  },
  async mounted() {
    const manageCityList = await getMarketCityList(true)
    const { code, data } = await getFinanceUnionCityList()
    
    if(code == 0){
      let cityList = []
      data.list.map(el=>{
        if(manageCityList.some(c=>c.adcode == el.adcode)){
          cityList = cityList.concat(el.city_list)
        }
      })
      this.cityList = cityList
      this.suggestCityList = cityList
      this.uniCityList = data.list

      console.log("cityList", cityList, data.list)
    }

    await this.initSkulist()

    this.hourList = [8,9,10,11,12,13,14,15,16,17,18,19,20,21,22].map(el=>{
      return {
        hour: el,
        // name: el+":00~"+(el+1)+":00",
        name: el+"~"+(el+1),
      }
    })
    this.hourLimitColumn = ([{
      title: "日期",
      dataIndex: "date",
      align: "center",
      width: 110,
    },{
      title: "全部时段",
      dataIndex: "sum",
      align: "center",
      width: 110,
      sorter: true,
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'ascend',
    }]).concat(this.hourList.map(hour=>{
      return {
        title: hour.name,
        dataIndex: hour.hour,
        align: "center",
        sorter: true,
        sortDirections: ['ascend', 'descend'],
        defaultSortOrder: 'ascend',
      }
    }))

    // 默认搜索
    this.dateArr = [
      this.formatDate(new Date()),
      this.formatDate(new Date((new Date().getTime() + 7*24*3600*1000))),
    ]
    if(this.cityList.length){
      this.activeCity = this.cityList[0]
      this.activeCityAdcode = this.cityList[0].adcode
      this.handlerSearch()
    }
  },
  methods: {

    handlerTabChange(val){
      this.activeTabKey = val
      
      this.handlerSearch()
    },

    async initSkulist(){
      const {code, data} = await getSpecialSkuList({
        get_type: 1
      })

      if (code === 0) {
        // this.goodsList = data.list
        // debug 写死光夜
        this.goodsList = [
          {
            goods_id: 734,
            goods_name: "萧逸",
          },{
            goods_id: 735,
            goods_name: "陆沉",
          },{
            goods_id: 736,
            goods_name: "齐司礼",
          },{
            goods_id: 737,
            goods_name: "查理苏",
          },{
            goods_id: 738,
            goods_name: "夏鸣星",
          },{
            goods_id: 723,
            goods_name: "萧逸 早鸟",
          },{
            goods_id: 722,
            goods_name: "陆沉 早鸟",
          },{
            goods_id: 721,
            goods_name: "齐司礼 早鸟",
          },{
            goods_id: 720,
            goods_name: "查理苏 早鸟",
          },{
            goods_id: 719,
            goods_name: "夏鸣星 早鸟",
          },
        ]
        // 组装表格所需数据
        let skuArr = []
        data.list.map((goods) => {
          goods.sku_data.map((item) => {
            let obj = goods
                obj.flavor_name = item.flavor_name
            if (item.specification_list.length > 0) {
              item.specification_list.map((el) => {
                obj = { ...obj, ...el }
                skuArr.push(obj)
              })
            }
          })
        })

        this.skuList = skuArr
        this.dailyLimitColumn = ([{
          title: "日期",
          dataIndex: "date",
          align: "center",
          width: 110,
        },{
          title: "所有产品",
          dataIndex: "sum",
          align: "center",
          sorter: true,
          sortDirections: ['ascend', 'descend'],
          defaultSortOrder: 'ascend',
        }]).concat(skuArr.map(sku=>{
          return {
            title: sku.goods_name,
            dataIndex: sku.sku_id,
            align: "center",
            sorter: true,
            sortDirections: ['ascend', 'descend'],
            defaultSortOrder: 'ascend',
          }
        }))
      }
    },

    async initSkuDailyLimit(){
      const {code, data} = await getDayActGoodsMaxStock({
        day_start: this.dateArr[0],
        day_end:   this.dateArr[1],
        outlets_city_adcode: this.activeCityAdcode,
      })

      if(code == 0){
        const date_list = this.getDateRange(this.dateArr[0], this.dateArr[1])

        if(data.list.length){
          const sku_ids = []
          data.list.map(date=>{
            date.sku_data.map(sku=>{
              if(!sku_ids.includes(sku.sku_id)){
                sku_ids.push(sku.sku_id)
              }
            })
          })

          const sku_data = sku_ids.map(sku_id=>{
            let stock = 0
            let initial_stock = 0
            data.list.map(d=>{
              const s = d.sku_data.find(s=>s.sku_id==sku_id)
              stock += (s ? s.stock : 0)
              initial_stock += (s ? s.initial_stock : 0)
            })
            return {
              sku_id: sku_id,
              stock: stock,
              initial_stock: initial_stock,
            }
          })
          data.list.push({
            day: "总计",
            sku_data: sku_data,
            is_sum: true
          })
          date_list.push("总计")
        }

        const list = date_list.map(date=>{
          const setdata = data.list.find(el=>el.day==date)
          let day_sum_stock = 0
          let day_sum_initial_stock = 0
          const item = {
            date: date,
            is_sum: setdata&&setdata.is_sum,
          }
          this.skuList.map(sku=>{
            const setdata_sku = setdata ? setdata.sku_data.find(s=>s.sku_id==sku.sku_id) : null
            if(setdata_sku){
              item[sku.sku_id] = setdata_sku.stock+"/"+setdata_sku.initial_stock
              item["stock_"+sku.sku_id] = setdata_sku.stock
              day_sum_stock += setdata_sku.stock
              day_sum_initial_stock += setdata_sku.initial_stock
            }else{
              item[sku.sku_id] = "—"
            }
          })
          item["sum"] = day_sum_initial_stock ? (day_sum_stock+"/"+day_sum_initial_stock) : "—"
          item["stock_sum"] = day_sum_stock
          return item
        })
        this.dailyLimitData = list
      }
    },

    async initCityHourLimit(){
      const {code, data} = await getHourActMaxStock({
        day_start: this.dateArr[0],
        day_end:   this.dateArr[1],
        outlets_city_adcode: this.activeCityAdcode,
      })

      if(code == 0){
        const date_list = this.getDateRange(this.dateArr[0], this.dateArr[1])

        if(data.list.length){
          const hour_stock_data = this.hourList.map(hour=>{
            let stock = 0
            let initial_stock = 0
            data.list.map(d=>{
              const h = d.hour_stock_data.find(h=>h.hour==hour.hour)
              stock += (h ? h.stock : 0)
              initial_stock += (h ? h.initial_stock : 0)
            })
            return {
              hour: hour.hour,
              stock: stock,
              initial_stock: initial_stock,
            }
          })
          data.list.push({
            day: "总计",
            hour_stock_data: hour_stock_data,
            is_sum: true,
          })
          date_list.push("总计")
        }

        const list = date_list.map(date=>{
          const setdata = data.list.find(el=>el.day==date)
          let day_sum_stock = 0
          let day_sum_initial_stock = 0
          const item = {
            date: date,
            is_sum: setdata && setdata.is_sum,
          }
          this.hourList.map(hour=>{
            const setdata_hour = setdata ? setdata.hour_stock_data.find(h=>h.hour==hour.hour) : null
            item[hour.hour] = setdata_hour ? (setdata_hour.stock+"/"+setdata_hour.initial_stock) : "—"
            if(setdata_hour){
              item[hour.hour] = setdata_hour.stock+"/"+setdata_hour.initial_stock
              item["limit_"+hour.hour] = setdata_hour.stock
              day_sum_stock += setdata_hour.stock
              day_sum_initial_stock += setdata_hour.initial_stock
            }else{
              item[hour.hour] = "—"
            }
          })
          item["sum"] = day_sum_initial_stock ? (day_sum_stock+"/"+day_sum_initial_stock) : "—"
          item["limit_sum"] = day_sum_stock
          return item
        })
        this.hourLimitData = list
      }
    },

    handlerSearch(){
      if(!this.activeCityAdcode){
        this.$message.info("请选择城市")
        return
      }
      if(this.dateArr.length < 2){
        return
      }

      if(this.activeTabKey == 1){
        this.initSkuDailyLimit()
      }else if(this.activeTabKey == 2){
        this.initCityHourLimit()
      }
    },

    handlerCityChange(val){
      this.activeCity = this.cityList.find(el=>el.adcode==val)
      this.activeCityAdcode = val
      this.handlerSearch()
    },
    handlerSuggestCity(keyword){
      keyword = keyword || ""
      if(keyword){
        this.suggestCityList = this.cityList.filter(el=>{
          return el.name.indexOf(keyword) > -1
        })
      }else{
        this.suggestCityList = this.cityList
      }
      if(!this.suggestCityList.length){
        this.suggestCityList = this.cityList
      }
    },
    handlerSuggestCityBlur(){
      this.suggestCityList = this.cityList
    },

    handlerSetDaily(){
      // this.dailyForm.sku_id = undefined
      this.dailyForm.sku_ids = []
      this.dailyForm.sku_selected = []
      this.dailyForm.dateArr = []
      this.dailyForm.initial_stock = 0
      if(this.dateArr.length == 2){
        this.dailyForm.dateArr = [
          this.dateArr[0],
          this.dateArr[1],
        ]
      }
      this.isShowSetDailyStock = true
    },
    onDailySkuChanged(){
      this.dailyForm.sku_selected = this.skuList.filter(sku=>{
        return this.dailyForm.sku_ids.includes(sku.sku_id)
      }).map(sku=>{
        return {
          sku_id:             sku.sku_id,
          goods_name:         sku.goods_name,
          flavor_name:        sku.flavor_name,
          specification_name: sku.specification_name,
          number: 0,
        }
      })
    },
    async handlerSetDailyOk(){
      if(this.dailyForm.dateArr.length < 2){
        this.$message.info("请选择日期")
        return
      }
      // if(!this.dailyForm.sku_id){
      //   this.$message.info("请选择产品")
      //   return
      // }
      // if(this.dailyForm.initial_stock < 0){
      //   this.$message.info("请填写上限数量")
      //   return
      // }
      if(!this.dailyForm.sku_selected.length){
        this.$message.info("请选择产品")
        return
      }
      if(this.dailyForm.sku_selected.some(el=>!(el.number>=0))){
        this.$message.info("请填写上限数量")
        return
      }

      const date_list = this.getDateRange(this.dailyForm.dateArr[0], this.dailyForm.dateArr[1])
      // const sku_data = date_list.map(date=>{
      //   return {
      //     "day": date,
      //     "sku_id": this.dailyForm.sku_id,
      //     "initial_stock": this.dailyForm.initial_stock
      //   }
      // })
      const sku_data = []
      date_list.map(date=>{
        this.dailyForm.sku_selected.map(sku=>{
          sku_data.push({
            "day": date,
            "sku_id": sku.sku_id,
            "initial_stock": sku.number,
          })
        })
      })

      // const sku_data = []
      // date_list.map(date=>{
      //   this.dailyForm.sku_ids.map(sku_id=>{
      //     sku_data.push({
      //       "day": date,
      //       "sku_id": sku_id,
      //       "initial_stock": this.dailyForm.initial_stock
      //     })
      //   })
      // })

      const { code } = await setDayActGoodsMaxStock({
        outlets_city_adcode: this.activeCityAdcode,
        update_sku_data: JSON.stringify(sku_data)
      })
      if(code == 0){
        this.$message.success("已设置")
        // this.isShowSetDailyStock = false
        if(this.dateArr.length == 2){
          this.initSkuDailyLimit()
        }
      }
    },

    // 每个时段的交付上限
    handlerSetHour(){
      this.hourForm.dateArr = []
      this.hourForm.hour_list = this.hourList.map(h=>{
        return {
          hour: h.hour,
          name: h.name,
          checked: false,
        }
      })
      this.checkAllHour = false
      this.hourForm.initial_stock = 0
      if(this.dateArr.length == 2){
        this.hourForm.dateArr = [
          this.dateArr[0],
          this.dateArr[1],
        ]
      }

      this.isShowSetHourStock = true
    },
    async handlerSetHourOk(){
      if(this.hourForm.dateArr.length < 2){
        this.$message.info("请选择日期")
        return
      }
      if(!this.hourForm.hour_list.some(h=>h.checked)){
        this.$message.info("请选择时间")
        return
      }
      if(this.hourForm.initial_stock < 0){
        this.$message.info("请填写上限数量")
        return
      }

      const date_list = this.getDateRange(this.hourForm.dateArr[0], this.hourForm.dateArr[1])
      const hour_data = []
      date_list.map(date=>{
        this.hourForm.hour_list.filter(h=>h.checked).map(h=>{
          hour_data.push({
            "day": date,
            "hour": h.hour,
            "initial_stock": this.hourForm.initial_stock,
          })
        })
      })

      const { code } = await setHourActMaxStock({
        outlets_city_adcode: this.activeCityAdcode,
        update_data: JSON.stringify(hour_data)
      })
      if(code == 0){
        this.$message.success("已设置")
        // this.isShowSetHourStock = false
        if(this.dateArr.length == 2){
          this.initCityHourLimit()
        }
      }
    },

    onCheckAllHour(){
      this.hourForm.hour_list.map(h=>{
        h.checked = this.checkAllHour
      })
    },


    // 排序
    handlerLimitDailyChange(pagination, filters, sorter){
      if(!sorter.order){
        // 默认按日期排序
        this.dailyLimitData.sort((a,b)=>{
          if(a.is_sum) return 1
          if(b.is_sum) return -1
          return new Date(a.date.replace(/-/,"/")).getTime() - new Date(b.date.replace(/-/,"/")).getTime()
        })
      }else{
        if (sorter.order === "ascend") {//正序
          this.dailyLimitData.sort((a,b)=>{
            if(a.is_sum) return 1
            if(b.is_sum) return -1
            return a["stock_"+sorter.columnKey] - b["stock_"+sorter.columnKey]
          })
        } else if (sorter.order === "descend") {//倒序
          this.dailyLimitData.sort((a,b)=>{
            if(a.is_sum) return 1
            if(b.is_sum) return -1
            return b["stock_"+sorter.columnKey] - a["stock_"+sorter.columnKey]
          })
        }
      }
    },
    handlerHourLimitChange(pagination, filters, sorter){
      if(!sorter.order){
        // 默认按日期排序
        this.hourLimitData.sort((a,b)=>{
          if(a.is_sum) return 1
          if(b.is_sum) return -1
          return new Date(a.date.replace(/-/,"/")).getTime() - new Date(b.date.replace(/-/,"/")).getTime()
        })
      }else{
        if (sorter.order === "ascend") {//正序
          this.hourLimitData.sort((a,b)=>{
            if(a.is_sum) return 1
            if(b.is_sum) return -1
            return a["limit_"+sorter.columnKey] - b["limit_"+sorter.columnKey]
          })
        } else if (sorter.order === "descend") {//倒序
          this.hourLimitData.sort((a,b)=>{
            if(a.is_sum) return 1
            if(b.is_sum) return -1
            return b["limit_"+sorter.columnKey] - a["limit_"+sorter.columnKey]
          })
        }
      }
    },

    handlerExport(){
      this.isShowExport = true
    },
    handlerExportOk(){
      if(!this.exportForm.goods_ids.length) {
        this.$message.info("请选择产品")
        return
      }
      // if(!this.exportForm.date){
      //   this.$message.info("请选择日期")
      //   return
      // }
      if(this.exportForm.orderDateArr.length < 2 && this.exportForm.dateArr.length < 2){
        this.$message.info("至少选择下单日期 或者 自提/配送日期")
        return
      }

      this.checkExportMode()
      if(!this.exportForm.mode) {
        this.$message.info("请选择导出模式")
        return
      }

      if(this.exportLoading) return

      this.exportLoading = true
      const all_city_list = this.uniCityList.reduce((list, item)=>{
        return list.concat(item.city_list.map(el=>{
          return {
            adcode: el.adcode,
            name: el.name,
          }
        }))
      }, []).concat([{
        adcode: "0",
        name: "其他",
      }])

      const goods_ids = this.exportForm.goods_ids.map(el=>el)

      if(this.exportForm.mode == 1){
        this.startStatisticsSum(all_city_list, goods_ids)
      }else if(this.exportForm.mode == 2){
        const date_list = this.getDateRange(this.exportForm.orderDateArr[0], this.exportForm.orderDateArr[1])
        let date_goods_list = []
        date_list.map(date=>{
          date_goods_list = date_goods_list.concat(goods_ids.map(goods_id=>{
            return {
              date: date,
              goods_id: goods_id,
            }
          }))
        })

        this.startStatisticsOrderDateGoods(all_city_list, date_goods_list)
      }else if(this.exportForm.mode == 3){
        const date_list = this.getDateRange(this.exportForm.orderDateArr[0], this.exportForm.orderDateArr[1])
        let date_goods_list = []
        date_list.map(date=>{
          date_goods_list = date_goods_list.concat(goods_ids.map(goods_id=>{
            return {
              date: date,
              goods_id: goods_id,
            }
          }))
        })
        const date_list_city = date_list.map(date=>{
          const item = {
            date: date,
          }
          all_city_list.map(city=>{
            item[city.adcode] = {
              adcode: city.adcode,
              city_name: city.name,
            }
          })
          return item
        })

        this.startStatisticsOrderDateCity(date_list_city, all_city_list, date_goods_list)
      }else if(this.exportForm.mode == 4){
        const date_list = this.getDateRange(this.exportForm.dateArr[0], this.exportForm.dateArr[1])
        let date_goods_list = []
        date_list.map(date=>{
          date_goods_list = date_goods_list.concat(goods_ids.map(goods_id=>{
            return {
              date: date,
              goods_id: goods_id,
            }
          }))
        })

        this.startStatisticsDailyGoods(all_city_list, date_goods_list)
      }else if(this.exportForm.mode == 5){
        const date_list = this.getDateRange(this.exportForm.dateArr[0], this.exportForm.dateArr[1])
        let date_goods_list = []
        date_list.map(date=>{
          date_goods_list = date_goods_list.concat(goods_ids.map(goods_id=>{
            return {
              date: date,
              goods_id: goods_id,
            }
          }))
        })
        const date_list_city = date_list.map(date=>{
          const item = {
            date: date,
          }
          all_city_list.map(city=>{
            item[city.adcode] = {
              adcode: city.adcode,
              city_name: city.name,
            }
          })
          return item
        })

        this.startStatisticsDailyCity(date_list_city, all_city_list, date_goods_list)
      }
    },

    // 模式1：x：产品、y：城市
    async startStatisticsSum(all_city_list, goods_ids){
      let add_time_start = ""
      let add_time_end   = ""
      if(this.exportForm.orderDateArr.length == 2){
        add_time_start = this.exportForm.orderDateArr[0] + " 00:00:00"
        add_time_end   = this.exportForm.orderDateArr[1] + " 23:59:59"
      }
      if(goods_ids.length){
        const goods_id = goods_ids.pop()
        const { code, data } = await getActGoodsStatistics({
          goods_id: goods_id,
          add_time_start: add_time_start,
          add_time_end:   add_time_end,
          delivery_time_start: this.exportForm.dateArr[0] + " 00:00:00",
          delivery_time_end:   this.exportForm.dateArr[1] + " 23:59:59",
        })
        if(code == 0){
          all_city_list.map(city=>{
            const item = data.list.find(el=>el.adcode==city.adcode)
            city[goods_id] = item ? item.number : 0
          })
        }
        this.startStatisticsSum(all_city_list, goods_ids)
      }else{
        const excel = []
        const goods_list = this.goodsList.filter(gd=>{
          return this.exportForm.goods_ids.includes(gd.goods_id)
        })
        const header = ["城市"].concat(goods_list.map(gd=>gd.goods_name))
        excel.push(header)

        all_city_list.map(city=>{
          const list = goods_list.map(gd=>{
            return city[gd.goods_id] || 0
          })
          excel.push([city.name].concat(list))
        })

        const XLSX = require('xlsx')
        let wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(excel), "城市预约分布")

        // XLSX.writeFile(wb, this.exportForm.date+"城市预约分布.xlsx")
        XLSX.writeFile(wb, "城市预约分布.xlsx")

        this.exportLoading = false
        // this.isShowExport = false
      }
    },

    // 模式2：x：下单日期*产品、y：城市
    async startStatisticsOrderDateGoods(all_city_list, date_goods_list){
      let delivery_time_start = ""
      let delivery_time_end   = ""
      if(this.exportForm.orderDateArr.length == 2){
        delivery_time_start = this.exportForm.dateArr[0] + " 00:00:00"
        delivery_time_end   = this.exportForm.dateArr[1] + " 23:59:59"
      }
      if(date_goods_list.length){
        const date_goods = date_goods_list.pop()
        const goods_id = date_goods.goods_id
        const { code, data } = await getActGoodsStatistics({
          goods_id: goods_id,
          add_time_start: date_goods.date + " 00:00:00",
          add_time_end:   date_goods.date + " 23:59:59",
          delivery_time_start: delivery_time_start,
          delivery_time_end:   delivery_time_end,
        })
        if(code == 0){
          all_city_list.map(city=>{
            const item = data.list.find(el=>el.adcode==city.adcode)
            if(!city[date_goods.date]){
              city[date_goods.date] = {}
            }
            city[date_goods.date][goods_id] = item ? item.number : 0
          })
        }
        this.startStatisticsOrderDateGoods(all_city_list, date_goods_list)
      }else{
        const excel = []
        const style_list = []
        const goods_list = this.goodsList.filter(gd=>{
          return this.exportForm.goods_ids.includes(gd.goods_id)
        })

        const date_list = this.getDateRange(this.exportForm.orderDateArr[0], this.exportForm.orderDateArr[1])
        const header1 = ["下单日期"]
        const header2 = ["城市"]
        date_list.map(date=>{
          goods_list.map((gd,index)=>{
            if(index==0){
              style_list.push({
                s: { r: 0, c: header1.length },
                e: { r: 0, c: header1.length+goods_list.length-1 }
              })
              header1.push(date)
            }else{
              header1.push("")
            }
            header2.push(gd.goods_name)
          })
        })
        excel.push(header1)
        excel.push(header2)

        all_city_list.map(city=>{
          let line_list = [city.name]
          date_list.map((date)=>{
            goods_list.map(goods=>{
              const number = city[date][goods.goods_id]
              line_list.push(number)
            })
          })
          excel.push(line_list)
        })

        const XLSX = require('xlsx')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(excel)
              ws['!merges'] = style_list

        XLSX.utils.book_append_sheet(wb, ws, "城市预约分布")
        XLSX.writeFile(wb, "城市预约分布.xlsx")

        this.exportLoading = false
        // this.isShowExport = false
      }
    },

    // 模式3：x：产品、y：下单日期*城市
    async startStatisticsOrderDateCity(date_list_city, all_city_list, date_goods_list){
      let delivery_time_start = ""
      let delivery_time_end   = ""
      if(this.exportForm.orderDateArr.length == 2){
        delivery_time_start = this.exportForm.dateArr[0] + " 00:00:00"
        delivery_time_end   = this.exportForm.dateArr[1] + " 23:59:59"
      }
      if(date_goods_list.length){
        const date_goods = date_goods_list.pop()
        const goods_id = date_goods.goods_id
        const { code, data } = await getActGoodsStatistics({
          goods_id: goods_id,
          add_time_start: date_goods.date + " 00:00:00",
          add_time_end:   date_goods.date + " 23:59:59",
          delivery_time_start: delivery_time_start,
          delivery_time_end:   delivery_time_end,
        })
        if(code == 0){
          const date_item = date_list_city.find(d=>d.date==date_goods.date)
          all_city_list.map(city=>{
            const item = data.list.find(el=>el.adcode==city.adcode)
            date_item[city.adcode][goods_id] = item ? item.number : 0
          })
        }
        this.startStatisticsOrderDateCity(date_list_city, all_city_list, date_goods_list)
      }else{
        const excel = []
        const style_list = []
        const goods_list = this.goodsList.filter(gd=>{
          return this.exportForm.goods_ids.includes(gd.goods_id)
        })

        const header = ["下单日期","城市"]
        goods_list.map((gd)=>{
          header.push(gd.goods_name)
        })
        excel.push(header)

        date_list_city.map(date_item=>{
          all_city_list.map(city=>{
            let line_list = [date_item.date, city.name]
            goods_list.map(goods=>{
              const number = date_item[city.adcode][goods.goods_id]
              line_list.push(number)
            })
            excel.push(line_list)
          })
        })

        const XLSX = require('xlsx')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(excel)
              ws['!merges'] = style_list

        XLSX.utils.book_append_sheet(wb, ws, "城市预约分布")
        XLSX.writeFile(wb, "城市预约分布.xlsx")

        this.exportLoading = false
        // this.isShowExport = false
      }
    },

    // 模式4：x：自提/配送日期*产品、y：城市
    async startStatisticsDailyGoods(all_city_list, date_goods_list){
      let add_time_start = ""
      let add_time_end   = ""
      if(this.exportForm.orderDateArr.length == 2){
        add_time_start = this.exportForm.orderDateArr[0] + " 00:00:00"
        add_time_end   = this.exportForm.orderDateArr[1] + " 23:59:59"
      }
      if(date_goods_list.length){
        const date_goods = date_goods_list.pop()
        const goods_id = date_goods.goods_id
        const { code, data } = await getActGoodsStatistics({
          goods_id: goods_id,
          // delivery_time_start: this.exportForm.date + " 00:00:00",
          // delivery_time_end:   this.exportForm.date + " 23:59:59",
          add_time_start: add_time_start,
          add_time_end:   add_time_end,
          delivery_time_start: date_goods.date + " 00:00:00",
          delivery_time_end:   date_goods.date + " 23:59:59",
        })
        if(code == 0){
          all_city_list.map(city=>{
            const item = data.list.find(el=>el.adcode==city.adcode)
            if(!city[date_goods.date]){
              city[date_goods.date] = {}
            }
            city[date_goods.date][goods_id] = item ? item.number : 0
          })
        }
        this.startStatisticsDailyGoods(all_city_list, date_goods_list)
      }else{
        const excel = []
        const style_list = []
        const goods_list = this.goodsList.filter(gd=>{
          return this.exportForm.goods_ids.includes(gd.goods_id)
        })

        const date_list = this.getDateRange(this.exportForm.dateArr[0], this.exportForm.dateArr[1])
        const header1 = ["自提/配送日期"]
        const header2 = ["城市"]
        date_list.map(date=>{
          goods_list.map((gd,index)=>{
            if(index==0){
              style_list.push({
                s: { r: 0, c: header1.length },
                e: { r: 0, c: header1.length+goods_list.length-1 }
              })
              header1.push(date)
            }else{
              header1.push("")
            }
            header2.push(gd.goods_name)
          })
        })
        excel.push(header1)
        excel.push(header2)

        all_city_list.map(city=>{
          let line_list = [city.name]
          date_list.map((date)=>{
            goods_list.map(goods=>{
              const number = city[date][goods.goods_id]
              line_list.push(number)
            })
          })
          excel.push(line_list)
        })

        const XLSX = require('xlsx')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(excel)
              ws['!merges'] = style_list

        XLSX.utils.book_append_sheet(wb, ws, "城市预约分布")
        XLSX.writeFile(wb, "城市预约分布.xlsx")

        this.exportLoading = false
        // this.isShowExport = false
      }
    },

    // 模式5：x：产品、y：自提/配送日期*城市
    async startStatisticsDailyCity(date_list_city, all_city_list, date_goods_list){
      let add_time_start = ""
      let add_time_end   = ""
      if(this.exportForm.orderDateArr.length == 2){
        add_time_start = this.exportForm.orderDateArr[0] + " 00:00:00"
        add_time_end   = this.exportForm.orderDateArr[1] + " 23:59:59"
      }
      if(date_goods_list.length){
        const date_goods = date_goods_list.pop()
        const goods_id = date_goods.goods_id
        const { code, data } = await getActGoodsStatistics({
          goods_id: goods_id,
          add_time_start: add_time_start,
          add_time_end:   add_time_end,
          delivery_time_start: date_goods.date + " 00:00:00",
          delivery_time_end:   date_goods.date + " 23:59:59",
        })
        if(code == 0){
          const date_item = date_list_city.find(d=>d.date==date_goods.date)
          all_city_list.map(city=>{
            const item = data.list.find(el=>el.adcode==city.adcode)
            date_item[city.adcode][goods_id] = item ? item.number : 0
          })
        }
        this.startStatisticsDailyCity(date_list_city, all_city_list, date_goods_list)
      }else{
        const excel = []
        const style_list = []
        const goods_list = this.goodsList.filter(gd=>{
          return this.exportForm.goods_ids.includes(gd.goods_id)
        })

        // const date_list = this.getDateRange(this.exportForm.dateArr[0], this.exportForm.dateArr[1])
        const header = ["自提/配送日期","城市"]
        goods_list.map((gd)=>{
          header.push(gd.goods_name)
        })
        excel.push(header)

        date_list_city.map(date_item=>{
          all_city_list.map(city=>{
            let line_list = [date_item.date, city.name]
            goods_list.map(goods=>{
              const number = date_item[city.adcode][goods.goods_id]
              line_list.push(number)
            })
            excel.push(line_list)
          })
        })

        const XLSX = require('xlsx')
        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(excel)
              ws['!merges'] = style_list

        XLSX.utils.book_append_sheet(wb, ws, "城市预约分布")
        XLSX.writeFile(wb, "城市预约分布.xlsx")

        this.exportLoading = false
        // this.isShowExport = false
      }
    },


    checkExportMode(){
      const hasOrderDate = this.exportForm.orderDateArr.length == 2
      const hasDlvrDate = this.exportForm.dateArr.length == 2

      if(!hasOrderDate){
        if(this.exportForm.mode == 2 || this.exportForm.mode == 3){
          this.exportForm.mode = undefined
        }
      }
      if(!hasDlvrDate){
        if(this.exportForm.mode == 4 || this.exportForm.mode == 4){
          this.exportForm.mode = undefined
        }
      }
    },

    getDateRange(start_date, end_date){
      let start_time = new Date(start_date.replace(/-/g,"/")).getTime()
      let end_time = new Date(end_date.replace(/-/g,"/")).getTime()

      const arr = []
      const date = new Date()
      let date_time = start_time
      while(date_time <= end_time){
        date.setTime(date_time)
        date_time = date_time + 24*3600*1000

        let year = date.getFullYear()
        let month = date.getMonth()
        let day = date.getDate()
        month = month+1
        if(month < 10){
          month = "0"+month
        }
        if(day < 10){
          day = "0"+day
        }

        arr.push(year+"-"+month+"-"+day)
      }

      return arr
    },

    formatDate(date){
      let year = date.getFullYear()
      let month = date.getMonth()
      let day = date.getDate()
      month = month+1
      if(month < 10){
        month = "0"+month
      }
      if(day < 10){
        day = "0"+day
      }
      return year+"-"+month+"-"+day
    },

  },
};
</script>

<style lang="less">
.hour-con{
  display: flex;
  flex-wrap: wrap;
  .hour-item{
    margin-left: 0 !important;
    margin-right: 10px;
  }
}
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
.set-daily-stock{
  .sku-item-name{
    flex: 1;
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.mode-radio{
  display: flex;
  flex-direction: column;
}
</style>
