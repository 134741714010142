import ajax from '@/utils/ajax.js'

/**
 * 获取商品列表
 * @param {*} params 
 * @returns 
 */
export function getGoodsList(params) {
  return ajax.post('/Goods/getList',params)
}

/**
 * 获取商品详情
 * @param {*} params 
 * @returns 
 */
 export function getGoodsDetail(params) {
  return ajax.post('/Goods/detail',params)
}


/**
 * 新增商品
 * @param {*} params 
 * @returns 
 */
 export function addGoods(params) {
  return ajax.post('/Goods/add',params)
}
/**
 * 编辑商品
 * @param {*} params 
 * @returns 
 */
 export function eidtGoods(params) {
  return ajax.post('/Goods/update',params)
}

/**
 * 设置商品的详情入口（小程序门店页）
 * @param {*} params 
 * @returns 
 */
 export function setGoodsDetailEnter(params) {
  return ajax.post('/Goods/setOther',params)
}

/**
 * 编辑商品：设置销售模式
 * @param {*} params 
 * @returns 
 */
 export function setSaleModel(params) {
  return ajax.post('/Goods/setSaleModel',params)
}

/**
 * 删除商品
 * @param {*} params 
 * @returns 
 */
 export function updateGoodsStatus(params) {
  return ajax.post('/Goods/updateStatus',params)
}

/**
 * 根据SKUID获取各城市蛋糕制作时间
 * @param {*} params 
 * @returns 
 */
export function getCityProductionTimeList(params) {
  return ajax.post('/OutletsCity/getGoodsSkuProductionTime',params)
}


/**
 * 导出全部商品
 * @param {*} params 
 * @returns 
 */
export function exportGoods(params) {
  return ajax.openurl('/Goods/exportList',params)
}


/**
 * 取货特殊sku列表（光夜）
 * @param {*} params 
 * @returns 
 */
export function getSpecialSkuList(params) {
  return ajax.post('/Goods/getGoodsSkuList',params)
}