import ajax from '@/utils/ajax.js'

/**
 * 区域爆款蛋糕的每日交付上限
 * @param {*} params 
 * @returns 
 */
export function getDayActGoodsMaxStock(params) {
  return ajax.post('/OutletsCityDayGoodsSkuStock/getStockData',params)
}
/**
 * 区域爆款蛋糕的每日交付上限: 设置
 * @param {*} params 
 * @returns 
 */
export function setDayActGoodsMaxStock(params) {
  return ajax.post('/OutletsCityDayGoodsSkuStock/editStock',params)
}


/**
 * 区域爆款蛋糕的每日交付上限
 * @param {*} params 
 * @returns 
 */
export function getHourActMaxStock(params) {
  return ajax.post('/OutletsCityDayHourStock/getStockData',params)
}
/**
 * 区域爆款蛋糕的每日交付上限: 设置
 * @param {*} params 
 * @returns 
 */
export function setHourActMaxStock(params) {
  return ajax.post('/OutletsCityDayHourStock/editStock',params)
}


/**
 * 爆款蛋糕 统计
 * @param {*} params 
 * @returns 
 */
export function getActGoodsStatistics(params) {
  return ajax.post('/RegionalStatisticsAllPermission/goodsFocUnionOcAdcode',params)
}